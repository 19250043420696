import { cn } from "@/lib/utils";

export function SolidarityIcon({ className }: { className?: string }) {
  return (
    <svg
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("h-[73px] w-[73px]", className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.0425 4.2401C67.3042 4.2401 65.8624 5.68159 65.8624 7.42017C65.8624 8.74082 65.8624 18.5988 65.8624 19.0804C65.8624 20.2675 64.9297 21.2005 63.7424 21.2005C62.555 21.2005 61.6223 20.2675 61.6223 19.0804C61.6223 17.5315 61.6223 3.6886 61.6223 3.18007C61.6223 1.44149 60.1805 0 58.4422 0C56.7039 0 55.2622 1.44149 55.2622 3.18007C55.2622 5.0183 55.2622 18.4029 55.2622 19.0804C55.2622 20.2675 54.3295 21.2005 53.1421 21.2005C51.9547 21.2005 51.0221 20.2675 51.0221 19.0804C51.0221 17.2246 51.0221 7.80108 51.0221 7.42017C51.0221 5.68159 49.5803 4.2401 47.842 4.2401C46.1037 4.2401 44.6619 5.68159 44.6619 7.42017C44.6619 8.68245 44.6619 22.6437 44.6619 23.3205C44.6619 24.5076 43.7292 25.4406 42.5419 25.4406H38.3018L51.0221 38.4436V40.5636H67.9825V37.0302C70.6961 34.0196 72.3639 29.8502 72.3639 25.8221C72.3639 24.5048 72.3639 8.38734 72.3639 7.42017C72.3639 5.68159 70.7808 4.2401 69.0425 4.2401Z"
        fill="#2FB0AB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0302 4.2401C34.0197 1.52644 29.9915 0 25.9634 0C24.6461 0 8.52839 0 7.56151 0C5.82293 0 4.38144 1.44178 4.38144 3.18007C4.38144 4.91837 5.82293 6.34601 7.56151 6.34601C8.88216 6.34601 18.7401 6.34601 19.2218 6.34601C20.4089 6.34601 21.3418 7.29283 21.3418 8.4802C21.3418 9.66757 20.4089 10.6002 19.2218 10.6002C17.6732 10.6002 3.82994 10.6002 3.32141 10.6002C1.58283 10.6002 0 12.042 0 13.7803C0 15.5186 1.58283 16.9463 3.32141 16.9463C5.15964 16.9463 18.5442 16.9463 19.2218 16.9463C20.4089 16.9463 21.3418 17.8931 21.3418 19.0804C21.3418 20.2678 20.4089 21.2005 19.2218 21.2005C17.3662 21.2005 7.94213 21.2005 7.56151 21.2005C5.82293 21.2005 4.38144 22.6423 4.38144 24.3806C4.38144 26.1189 5.82293 27.5606 7.56151 27.5606C8.82379 27.5606 22.7847 27.5606 23.4619 27.5606C24.649 27.5606 25.5819 28.4933 25.5819 29.6807V33.9208L38.3022 21.2005H40.4223V4.2401H37.0302Z"
        fill="#009EFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3418 33.9208V31.8008H4.38144V35.3342C1.66777 38.3447 0 42.5142 0 46.5423C0 47.8596 0 63.9775 0 64.9442C0 66.6828 1.58311 68.1243 3.32141 68.1243C5.05971 68.1243 6.50149 66.6828 6.50149 64.9442C6.50149 63.6236 6.50149 53.7656 6.50149 53.2839C6.50149 52.0969 7.43417 51.1639 8.62153 51.1639C9.8089 51.1639 10.7416 52.0969 10.7416 53.2839C10.7416 54.8326 10.7416 68.6761 10.7416 69.1843C10.7416 70.9229 12.1834 72.3644 13.9217 72.3644C15.66 72.3644 17.1017 70.9229 17.1017 69.1843C17.1017 67.3458 17.1017 53.9617 17.1017 53.2839C17.1017 52.0969 18.0344 51.1639 19.2218 51.1639C20.4092 51.1639 21.3418 52.0969 21.3418 53.2839C21.3418 55.1396 21.3418 64.5636 21.3418 64.9442C21.3418 66.6828 22.7836 68.1243 24.5219 68.1243C26.2602 68.1243 27.702 66.6828 27.702 64.9442C27.702 63.6819 27.702 49.721 27.702 49.0438C27.702 47.8568 28.6347 46.9238 29.822 46.9238H34.0621L21.3418 33.9208Z"
        fill="#2FB0AB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.1846 55.404C67.3455 55.404 53.8227 55.404 53.1429 55.404C51.9558 55.404 51.0228 54.4713 51.0228 53.2839C51.0228 52.0966 51.9558 51.1498 53.1429 51.1498C54.9985 51.1498 64.4225 51.1498 64.8032 51.1498C66.5417 51.1498 67.9832 49.7221 67.9832 47.9838C67.9832 46.2455 66.5417 44.8038 64.8032 44.8038C63.5409 44.8038 49.5799 44.8038 48.9028 44.8038C47.7157 44.8038 46.7827 43.8711 46.7827 42.6837V38.4436L34.0624 51.1639H31.9424V68.1243H35.3345C38.3451 70.838 42.3732 72.3644 46.4013 72.3644C47.7185 72.3644 63.8363 72.3644 64.8032 72.3644C66.5417 72.3644 67.9832 70.9226 67.9832 69.1843C67.9832 67.446 66.5417 66.0042 64.8032 66.0042C63.4825 66.0042 53.6246 66.0042 53.1429 66.0042C51.9558 66.0042 51.0228 65.0716 51.0228 63.8842C51.0228 62.6968 51.9558 61.75 53.1429 61.75C54.6921 61.75 68.6756 61.75 69.1846 61.75C70.9232 61.75 72.3647 60.3224 72.3647 58.5841C72.3647 56.8458 70.9232 55.404 69.1846 55.404Z"
        fill="#009EFF"
      />
    </svg>
  );
}
