import { cn } from "@/lib/utils";

export function TreeInHandIcon({ className }: { className?: string }) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("h-[28px] w-[28px]", className)}
    >
      <rect width="28" height="28" rx="4" fill="#FFFDFD" />
      <path
        d="M13.8822 12.2418C14.0516 12.3045 14.2177 12.151 14.1616 11.9876C13.7763 10.8664 12.3814 10.4481 11.0848 11.4429C10.4027 11.9662 10.1464 12.0774 10.1399 12.0802C10.5896 12.5048 11.1033 13.9296 12.2244 14.0872C13.4593 14.2987 14.5523 13.1512 13.6581 12.6261C13.07 12.2808 12.3776 12.0802 11.6353 12.0802C12.4134 11.9145 13.1902 11.9856 13.8822 12.2418Z"
        fill="#23C0EC"
      />
      <path
        d="M19.4744 11.4428C17.2361 9.72568 15.8086 12.4336 16.6959 12.1652C17.3261 11.9745 18.0151 11.933 18.7061 12.0801C17.5352 12.0801 16.4559 12.5869 15.7363 13.4118C15.7954 12.1155 15.8848 11.304 15.9884 10.7004C16.5865 10.681 17.3837 10.2249 17.8215 9.42668C18.5762 8.15106 18.2262 6.84313 18.1136 6.01782C17.5238 6.56018 14.9478 5.92468 13.9266 7.57742C13.4754 8.3075 13.6066 9.19591 14.0811 9.84605C14.2313 10.0519 14.5905 9.95311 14.6008 9.70334C14.6453 8.61681 15.2179 7.7959 16.2853 7.34123C15.8063 7.92735 15.4918 8.58355 15.3294 9.26179C15.0768 10.3183 14.8131 11.0114 14.7162 14.3789C14.7064 14.7204 14.9993 15.0031 15.361 14.9988C15.6229 14.9957 15.8472 14.8387 15.9479 14.6104C16.101 14.2632 16.3503 13.9038 16.756 13.5746C17.1414 13.977 17.728 14.1911 18.335 14.0872C19.4513 13.9303 19.9723 12.5023 20.4194 12.0801C20.4128 12.0774 20.1565 11.9662 19.4744 11.4428Z"
        fill="#23C0EC"
      />
      <path
        d="M22.7649 15.6387C22.0651 15.1903 21.3035 15.5098 20.9538 15.7005L16.5647 18.7345L13.9947 18.735L13.3523 18.1282H15.7124C16.4222 18.1282 17.2172 17.6915 17.2172 16.9144C17.2172 16.0892 16.4222 15.7005 15.7124 15.7005H14.828C14.1709 15.7005 13.4031 15.7558 12.8618 15.4821C12.3474 15.235 11.7551 15.0943 11.1229 15.0943C10.0612 15.0943 9.11104 15.494 8.45992 16.1244L5 19.3412L8.85489 22.982L10.1399 21.1616H16.2119C16.8595 21.1616 17.4846 20.9372 17.9687 20.5311L22.8088 16.4703C23.0779 16.2446 23.0627 15.8295 22.7649 15.6387Z"
        fill="#23C0EC"
      />
    </svg>
  );
}
