import { cn } from "@/lib/utils";

export function WaveIcon({ className }: { className?: string }) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("h-[28px] w-[28px]", className)}
    >
      <rect width="28" height="28" rx="4" fill="#FFFDFD" />
      <path
        d="M22.797 19.4414C22.5191 19.2482 22.2746 19.0111 22.0729 18.7393C21.5902 18.1908 20.9759 17.5106 19.835 17.5106C18.694 17.5106 18.1016 18.1688 17.597 18.7612C17.0923 19.3536 16.7632 19.7047 16.0172 19.7047C15.2712 19.7047 14.8982 19.2878 14.4375 18.7612C13.9767 18.2346 13.3624 17.5106 12.1995 17.5106C11.0366 17.5106 10.4662 18.1688 9.96149 18.7612C9.45686 19.3536 9.12773 19.7047 8.38175 19.7047C7.63576 19.7047 7.26275 19.2878 6.802 18.7612C6.48879 18.3476 6.09273 18.0038 5.63912 17.7519C5.42099 17.6429 5.15578 17.7313 5.04672 17.9494C4.93765 18.1675 5.02606 18.4327 5.24419 18.5418C5.58868 18.7494 5.89333 19.017 6.14375 19.3317C6.64838 19.9241 7.24078 20.5823 8.38175 20.5823C9.52271 20.5823 10.1151 19.9241 10.6197 19.3317C11.1244 18.7393 11.4535 18.3882 12.1995 18.3882C12.9455 18.3882 13.3185 18.8051 13.7792 19.3317C14.24 19.8583 14.8543 20.5823 16.0172 20.5823C17.1801 20.5823 17.7505 19.9241 18.2552 19.3317C18.7598 18.7393 19.089 18.3882 19.835 18.3882C20.5809 18.3882 20.9539 18.8051 21.4147 19.3317C21.6704 19.66 21.9736 19.9484 22.3143 20.1874C22.5203 20.3207 22.7953 20.2617 22.9286 20.0557C23.062 19.8497 23.003 19.5747 22.797 19.4414Z"
        fill="#23C0EC"
      />
      <path
        d="M22.797 16.1502C22.5191 15.9571 22.2746 15.7199 22.0729 15.4481C21.5902 14.8996 20.9759 14.2194 19.835 14.2194C18.694 14.2194 18.1016 14.8777 17.597 15.4701C17.0923 16.0625 16.7632 16.4136 16.0172 16.4136C15.2712 16.4136 14.8982 15.9967 14.4375 15.4701C13.9767 14.9435 13.3624 14.2194 12.1995 14.2194C11.0366 14.2194 10.4662 14.8777 9.96149 15.4701C9.45686 16.0625 9.12773 16.4136 8.38175 16.4136C7.63576 16.4136 7.26275 15.9967 6.802 15.4701C6.48879 15.0564 6.09273 14.7127 5.63912 14.4608C5.42099 14.3517 5.15578 14.4401 5.04672 14.6583C4.93765 14.8764 5.02606 15.1416 5.24419 15.2507C5.58868 15.4583 5.89333 15.7258 6.14375 16.0405C6.64838 16.6329 7.24078 17.2912 8.38175 17.2912C9.52271 17.2912 10.1151 16.633 10.6197 16.0405C11.1244 15.4481 11.4535 15.0971 12.1995 15.0971C12.9455 15.0971 13.3185 15.514 13.7792 16.0405C14.24 16.5671 14.8543 17.2912 16.0172 17.2912C17.1801 17.2912 17.7505 16.633 18.2552 16.0405C18.7598 15.4481 19.089 15.0971 19.835 15.0971C20.5809 15.0971 20.9539 15.514 21.4147 16.0405C21.6704 16.3689 21.9736 16.6573 22.3143 16.8962C22.5203 17.0295 22.7953 16.9706 22.9286 16.7646C23.062 16.5586 23.003 16.2835 22.797 16.1502Z"
        fill="#23C0EC"
      />
      <path
        d="M22.797 12.6396C22.5191 12.4464 22.2746 12.2093 22.0729 11.9375C21.5902 11.3889 20.9759 10.7088 19.835 10.7088C18.694 10.7088 18.1016 11.367 17.597 11.9594C17.0923 12.5519 16.7632 12.9029 16.0172 12.9029C15.2712 12.9029 14.8982 12.486 14.4375 11.9594C13.9767 11.4329 13.3624 10.7088 12.1995 10.7088C11.0366 10.7088 10.4662 11.367 9.96149 11.9594C9.45686 12.5518 9.12773 12.9029 8.38175 12.9029C7.63576 12.9029 7.26275 12.486 6.802 11.9594C6.48879 11.5458 6.09273 11.202 5.63912 10.9501C5.42099 10.8411 5.15578 10.9295 5.04672 11.1476C4.93765 11.3657 5.02606 11.6309 5.24419 11.74C5.58868 11.9476 5.89333 12.2151 6.14375 12.5299C6.64838 13.1223 7.24078 13.7805 8.38175 13.7805C9.52271 13.7805 10.1151 13.1223 10.6197 12.5299C11.1244 11.9375 11.4535 11.5864 12.1995 11.5864C12.9455 11.5864 13.3185 12.0033 13.7792 12.5299C14.24 13.0564 14.8543 13.7805 16.0172 13.7805C17.1801 13.7805 17.7505 13.1223 18.2552 12.5299C18.7599 11.9374 19.089 11.5864 19.835 11.5864C20.5809 11.5864 20.9539 12.0033 21.4147 12.5299C21.6704 12.8582 21.9736 13.1466 22.3143 13.3855C22.5203 13.5188 22.7953 13.4599 22.9286 13.2539C23.062 13.0479 23.003 12.7729 22.797 12.6396Z"
        fill="#23C0EC"
      />
      <path
        d="M5.24436 8.44895C5.58886 8.65658 5.8935 8.9241 6.14392 9.23882C6.64855 9.83122 7.24096 10.4895 8.38192 10.4895C9.52288 10.4895 10.1152 9.83126 10.6199 9.23882C11.1245 8.64642 11.4537 8.29537 12.1997 8.29537C12.9456 8.29537 13.3187 8.71227 13.7794 9.23882C14.2401 9.76541 14.8545 10.4895 16.0174 10.4895C17.1803 10.4895 17.7507 9.83126 18.2554 9.23882C18.76 8.64642 19.0891 8.29537 19.8351 8.29537C20.5811 8.29537 20.9541 8.71227 21.4149 9.23882C21.6706 9.56715 21.9738 9.85554 22.3144 10.0945C22.5204 10.2278 22.7955 10.1689 22.9288 9.96288C23.0621 9.75689 23.0032 9.48181 22.7971 9.34851C22.5192 9.15537 22.2747 8.91822 22.0731 8.64642C21.5904 8.0979 20.976 7.41772 19.8351 7.41772C18.6942 7.41772 18.1018 8.07594 17.5971 8.66838C17.0925 9.26078 16.7633 9.61183 16.0173 9.61183C15.2714 9.61183 14.8983 9.19493 14.4376 8.66838C13.9769 8.14179 13.3625 7.41772 12.1996 7.41772C11.0367 7.41772 10.4663 8.07594 9.96162 8.66838C9.45699 9.26078 9.12786 9.61183 8.38188 9.61183C7.63589 9.61183 7.26296 9.19493 6.80218 8.66834C6.48897 8.25467 6.0929 7.91093 5.63929 7.65904C5.42116 7.54997 5.15595 7.63837 5.04689 7.8565C4.93783 8.07463 5.02627 8.33988 5.24436 8.44895Z"
        fill="#23C0EC"
      />
    </svg>
  );
}
