import { cn } from "@/lib/utils";

export function WaterDropIcon({ className }: { className?: string }) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("h-[28px] w-[28px]", className)}
    >
      <rect width="28" height="28" rx="4" fill="#FFFDFD" />
      <path
        d="M20.2904 11.7302L20.2633 11.6935L20.2579 11.6827L17.7931 8.02372L15.3283 11.6828L15.3231 11.693L15.2957 11.7302C14.9002 12.2659 14.6912 12.9023 14.6912 13.5706C14.6912 15.281 16.0827 16.6725 17.7931 16.6725C19.5034 16.6725 20.8949 15.281 20.8949 13.5706C20.895 12.9022 20.6859 12.2658 20.2904 11.7302Z"
        fill="#23C0EC"
      />
      <path
        d="M17.7931 17.7293C15.5 17.7293 13.6344 15.8637 13.6344 13.5705C13.6344 12.6791 13.9121 11.8297 14.4376 11.1133L16.0579 8.70794L13.56 5L8.35563 12.7257C7.53737 13.8392 7.10498 15.1595 7.10498 16.5449C7.10498 20.1043 10.0007 23 13.56 23C16.9325 23 19.7088 20.4002 19.991 17.0994C19.3529 17.4984 18.5996 17.7293 17.7931 17.7293Z"
        fill="#23C0EC"
      />
    </svg>
  );
}
