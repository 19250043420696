import { cn } from "@/lib/utils";

export function CharityIcon1({ className }: { className?: string }) {
  return (
    <svg
      width="73"
      height="70"
      viewBox="0 0 73 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("h-[73px] w-[70px]", className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.8909 2.51529C55.0936 0.815979 52.7934 0 50.4751 0C47.8655 0 45.2301 1.03357 43.2651 3.01006L42.4219 3.85712L41.5787 3.00747C39.6137 1.03357 36.9783 0 34.3687 0C32.0504 0 29.7503 0.815979 27.9529 2.51529C23.9998 6.25325 23.9302 12.5065 27.7415 16.3351L42.4219 31.0823L57.1024 16.3351C60.9137 12.5065 60.844 6.25325 56.8909 2.51529Z"
        fill="#009EFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.3007 38.5918C69.0443 37.0557 66.3857 37.4909 64.0313 38.856C61.677 40.2212 48.1595 51.8314 48.1595 51.8314L34.8535 51.834C34.2346 51.834 33.5642 51.1346 33.5642 50.5155C33.5642 49.7876 34.1959 49.2203 34.8535 49.2203H42.9944C45.8439 49.2203 49.0337 47.3552 49.0337 44.0394C49.0337 40.5165 45.8439 38.8586 42.9944 38.8586C38.0511 38.8586 37.0892 38.8793 37.0892 38.8793C35.1088 38.8793 33.1 38.7602 31.5528 37.9287C29.4898 36.8666 27.1123 36.2656 24.5749 36.2656C20.3149 36.2656 16.501 37.9727 13.8862 40.6641L0 54.3985L15.4721 69.941L20.6295 64.7601H46.7438C49.3431 64.7601 51.8522 63.8017 53.794 62.0687L71.476 42.1433C72.5565 41.1796 72.4946 39.4078 71.3007 38.5918Z"
        fill="#009EFF"
      />
    </svg>
  );
}
