import { cn } from "@/lib/utils";

export function CharityIcon2({ className }: { className?: string }) {
  return (
    <svg
      width="47"
      height="73"
      viewBox="0 0 47 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("h-[73px] w-[47px]", className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.8229 34.0621C31.6941 34.0621 27.9672 35.7893 25.2869 38.5496V28.6814C26.3348 27.7755 27.3716 26.8865 28.3268 26.0752C35.0305 20.3807 39.8743 16.1265 39.8743 10.0872C39.8743 4.4309 35.747 0 30.4748 0C27.2311 0 24.8008 1.6918 23.1811 3.8486C21.5599 1.69321 19.1296 0 15.8845 0C10.6123 0 6.485 4.4309 6.485 10.0872C6.485 16.1265 11.3288 20.3807 18.0325 26.0752C18.9877 26.8865 20.0245 27.7755 21.0725 28.6814V38.5496C18.3921 35.7893 14.6652 34.0621 10.5364 34.0621H4.21484V36.1822C4.21484 40.3304 5.91606 44.0871 8.65401 46.7824H37.7053C40.4432 44.0871 42.1445 40.3304 42.1445 36.1822V34.0621H35.8229Z"
        fill="#2FB0AB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 51.0226V55.2627H5.43658L8.8095 72.3644H37.5489L40.9218 55.2627H46.3584V51.0226H0Z"
        fill="#009EFF"
      />
    </svg>
  );
}
