import { cn } from "@/lib/utils";

export default function SirenIcon({ className }: { className?: string }) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("h-[28px] w-[28px]", className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0002 8.16658C17.7783 8.16658 20.8574 11.1598 20.9954 14.9042L21.0002 15.1666V23.3333H22.1669C22.8112 23.3333 23.3335 23.8556 23.3335 24.4999C23.3335 25.0982 22.8832 25.5913 22.3029 25.6587L22.1669 25.6666H5.83354C5.18921 25.6666 4.66687 25.1443 4.66687 24.4999C4.66687 23.9016 5.11726 23.4085 5.69748 23.3411L5.83354 23.3333H7.00021V15.1666C7.00021 11.3006 10.1342 8.16658 14.0002 8.16658ZM12.9998 13.983L11.2604 16.8821C10.7892 17.6675 11.3549 18.6666 12.2709 18.6666H13.6896L12.9998 19.8163C12.6683 20.3689 12.8474 21.0854 13.4 21.417C13.9525 21.7485 14.6691 21.5694 15.0006 21.0168L16.74 18.1178C17.2112 17.3325 16.6455 16.3333 15.7296 16.3333H14.3108L15.0006 15.1835C15.3321 14.631 15.153 13.9144 14.6005 13.5828C14.0479 13.2514 13.3314 13.4305 12.9998 13.983ZM6.4657 5.99502L6.57561 6.09208L7.40057 6.91703C7.85618 7.37265 7.85618 8.11133 7.40057 8.56695C6.98 8.98751 6.31824 9.01986 5.86056 8.664L5.75065 8.56695L4.9257 7.74199C4.47008 7.28638 4.47008 6.54768 4.9257 6.09208C5.34626 5.67151 6.00803 5.63915 6.4657 5.99502ZM23.0748 6.09208C23.5304 6.54768 23.5304 7.28638 23.0748 7.74199L22.2497 8.56695C21.7941 9.02256 21.0555 9.02256 20.5998 8.56695C20.1442 8.11133 20.1442 7.37265 20.5998 6.91703L21.4248 6.09208C21.8805 5.63646 22.6191 5.63646 23.0748 6.09208ZM14.0002 2.33325C14.6446 2.33325 15.1669 2.85559 15.1669 3.49992V4.66659C15.1669 5.31091 14.6446 5.83325 14.0002 5.83325C13.3559 5.83325 12.8335 5.31091 12.8335 4.66659V3.49992C12.8335 2.85559 13.3559 2.33325 14.0002 2.33325Z"
        fill="#C1282D"
      />
    </svg>
  );
}
